import React from 'react';
import { Typography, Box } from '@mui/material';

export default function Home() {
  return (
    <Box>
      <Typography variant="h4" component="h1" gutterBottom>
        Welcome to the Home Page
      </Typography>
      <Typography variant="body1" paragraph>
        You are now logged in. Use the sidebar to navigate.
      </Typography>
    </Box>
  );
}
