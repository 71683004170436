import React, { useState, useEffect } from 'react';
import { Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress } from '@mui/material';
import { supabase } from '../supabaseClient';
import { useNavigate } from 'react-router-dom';

export default function Orders() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchOrders();
  }, []);

  async function fetchOrders() {
    try {
      setLoading(true);
      const { data: ordersData, error: ordersError } = await supabase
        .from('orders')
        .select(`
          id,
          order_date,
          shipping_order_number,
          customer_name,
          customer_po,
          shipping_via,
          shipping_address1,
          shipping_city,
          shipping_state,
          line_items (quantity)
        `)
        .order('order_date', { ascending: false });

      if (ordersError) throw ordersError;

      const ordersWithQuantity = ordersData.map(order => ({
        ...order,
        total_quantity: order.line_items.reduce((sum, item) => sum + item.quantity, 0)
      }));

      setOrders(ordersWithQuantity);
    } catch (error) {
      console.error('Error fetching orders:', error.message);
      alert('Error fetching orders. Please try again.');
    } finally {
      setLoading(false);
    }
  }

  const handleRowClick = (orderNumber) => {
    navigate(`/order/${orderNumber}`);
  };

  return (
    <Box>
      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="orders table">
            <TableHead>
              <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                <TableCell sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>Date</TableCell>
                <TableCell sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>Order Number</TableCell>
                <TableCell sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>Customer</TableCell>
                <TableCell sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>Purchase Order</TableCell>
                <TableCell sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>Quantity</TableCell>
                <TableCell sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>Shipping Via</TableCell>
                <TableCell sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>Ship To</TableCell>
                <TableCell sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>City</TableCell>
                <TableCell sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>State</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order) => (
                <TableRow 
                  key={order.shipping_order_number}
                  onClick={() => handleRowClick(order.shipping_order_number)}
                  sx={{ 
                    '&:hover': { 
                      backgroundColor: '#f5f5f5',
                      cursor: 'pointer'
                    }
                  }}
                >
                  <TableCell>{new Date(order.order_date).toLocaleDateString()}</TableCell>
                  <TableCell>{order.shipping_order_number}</TableCell>
                  <TableCell>{order.customer_name}</TableCell>
                  <TableCell>{order.customer_po}</TableCell>
                  <TableCell>{order.total_quantity}</TableCell>
                  <TableCell>{order.shipping_via}</TableCell>
                  <TableCell>{order.shipping_address1}</TableCell>
                  <TableCell>{order.shipping_city}</TableCell>
                  <TableCell>{order.shipping_state}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
}
