import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Box, Card, CardContent, CircularProgress, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import { supabase } from '../supabaseClient';

export default function OrderDetail() {
  const { orderNumber } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const [lineItems, setLineItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchOrderDetails();
  }, [orderNumber]);

  const handleBackToOrders = () => {
    navigate('/orders');
  };

  async function fetchOrderDetails() {
    try {
      setLoading(true);
      // Fetch order details
      const { data: orderData, error: orderError } = await supabase
        .from('orders')
        .select('*')
        .eq('shipping_order_number', orderNumber)
        .single();

      if (orderError) throw orderError;
      setOrder(orderData);

      // Fetch line items
      const { data: lineItemsData, error: lineItemsError } = await supabase
        .from('line_items')
        .select('*')
        .eq('order_id', orderData.id);

      if (lineItemsError) throw lineItemsError;

      // Fetch product details for each line item
      const lineItemsWithProducts = await Promise.all(
        lineItemsData.map(async (item) => {
          const { data: productData, error: productError } = await supabase
            .from('products')
            .select('style, color, size')
            .eq('upc', item.upc)
            .single();

          if (productError) throw productError;

          return { ...item, product: productData };
        })
      );

      setLineItems(lineItemsWithProducts);
    } catch (error) {
      console.error('Error fetching order details:', error.message);
      alert('Error fetching order details. Please try again.');
    } finally {
      setLoading(false);
    }
  }

  if (loading) {
    return <CircularProgress />;
  }

  if (!order) {
    return <Typography>Order not found</Typography>;
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        <strong>Order Number:</strong> {order.shipping_order_number}
      </Typography>
      <Typography variant="h6" gutterBottom>
        <strong>Order Date:</strong> {new Date(order.order_date).toLocaleDateString()}
      </Typography>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <Card variant="outlined" sx={{ boxShadow: 'none', border: '2px solid rgba(0, 0, 0, 0.12)' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                <strong>Customer Information</strong>
              </Typography>
              <Typography>Name: {order.customer_name}</Typography>
              <Typography>Address: {order.shipping_address1}, {order.shipping_city}, {order.shipping_state} {order.shipping_zip}</Typography>
              <Typography>PO: {order.customer_po}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card variant="outlined" sx={{ boxShadow: 'none', border: '2px solid rgba(0, 0, 0, 0.12)' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                <strong>Shipping Information</strong>
              </Typography>
              <Typography>Ship To: Scarlette Grillette</Typography>
              <Typography>Address: 500 Brea Mall Way, Brea, CA 92821</Typography>
              <Typography>Via: UPCG</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Box sx={{ mt: 4 }}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="line items table">
            <TableHead>
              <TableRow sx={{ height: '60px' }}>
                <TableCell sx={{ fontWeight: 'bold', fontSize: '1.1rem', verticalAlign: 'bottom', borderBottom: '2px solid rgba(224, 224, 224, 1)', paddingBottom: 1 }}>UPC</TableCell>
                <TableCell sx={{ fontWeight: 'bold', fontSize: '1.1rem', verticalAlign: 'bottom', borderBottom: '2px solid rgba(224, 224, 224, 1)', paddingBottom: 1 }}>Style</TableCell>
                <TableCell sx={{ fontWeight: 'bold', fontSize: '1.1rem', verticalAlign: 'bottom', borderBottom: '2px solid rgba(224, 224, 224, 1)', paddingBottom: 1 }}>Color</TableCell>
                <TableCell sx={{ fontWeight: 'bold', fontSize: '1.1rem', verticalAlign: 'bottom', borderBottom: '2px solid rgba(224, 224, 224, 1)', paddingBottom: 1 }}>Size</TableCell>
                <TableCell align="right" sx={{ fontWeight: 'bold', fontSize: '1.1rem', verticalAlign: 'bottom', borderBottom: '2px solid rgba(224, 224, 224, 1)', paddingBottom: 1 }}>Quantity</TableCell>
                <TableCell align="right" sx={{ fontWeight: 'bold', fontSize: '1.1rem', verticalAlign: 'bottom', borderBottom: '2px solid rgba(224, 224, 224, 1)', paddingBottom: 1 }}>Unit Price</TableCell>
                <TableCell align="right" sx={{ fontWeight: 'bold', fontSize: '1.1rem', verticalAlign: 'bottom', borderBottom: '2px solid rgba(224, 224, 224, 1)', paddingBottom: 1 }}>Net Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lineItems.map((item, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ verticalAlign: 'bottom', borderBottom: '1px solid rgba(224, 224, 224, 0.5)' }}>{item.upc}</TableCell>
                  <TableCell sx={{ verticalAlign: 'bottom', borderBottom: '1px solid rgba(224, 224, 224, 0.5)' }}>{item.product?.style || 'N/A'}</TableCell>
                  <TableCell sx={{ verticalAlign: 'bottom', borderBottom: '1px solid rgba(224, 224, 224, 0.5)' }}>{item.product?.color || 'N/A'}</TableCell>
                  <TableCell sx={{ verticalAlign: 'bottom', borderBottom: '1px solid rgba(224, 224, 224, 0.5)' }}>{item.product?.size || 'N/A'}</TableCell>
                  <TableCell align="right" sx={{ verticalAlign: 'bottom', borderBottom: '1px solid rgba(224, 224, 224, 0.5)' }}>{item.quantity}</TableCell>
                  <TableCell align="right" sx={{ verticalAlign: 'bottom', borderBottom: '1px solid rgba(224, 224, 224, 0.5)' }}>${item.unit_price.toFixed(2)}</TableCell>
                  <TableCell align="right" sx={{ verticalAlign: 'bottom', borderBottom: '1px solid rgba(224, 224, 224, 0.5)' }}>${item.net_price.toFixed(2)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleBackToOrders}
          sx={{ minWidth: '200px' }}
        >
          Back to Orders
        </Button>
      </Box>
    </Box>
  );
}
