import React from 'react';
import { Typography, Box } from '@mui/material';

export default function Option2() {
  return (
    <Box>
      <Typography variant="h4" component="h1" gutterBottom>
        Option2 Page
      </Typography>
      <Typography variant="body1" paragraph>
        This is a placeholder for the Option2 page.
      </Typography>
    </Box>
  );
}
